import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA_AWogGCrPGKOca2iU91heXh_-LQkxoHY",
  authDomain: "vakyashravan.firebaseapp.com",
  projectId: "vakyashravan",
  storageBucket: "vakyashravan.appspot.com",
  messagingSenderId: "432463800102",
  appId: "1:432463800102:web:0f227b89a6910e069083e7",
  measurementId: "G-Q7C2SVQ89N",
};
export const fire = firebase.initializeApp(firebaseConfig);

export const auth = fire.auth();
