import { Box, Button, Container, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./translater.css";
import { Link, useNavigate } from "react-router-dom";

const Translater = () => {
  const [transcript, setTranscript] = useState("");
  const [listening, setListening] = useState(false);
  const history = useNavigate();
  const logout = () => {
    localStorage.removeItem("items");
    history("/login");
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    const items1 = JSON.parse(localStorage.getItem("items"));
    console.log("hiiii");
    if (items1) {
      setItems(items1);
      history("/");
    } else {
      history("/login");
    }
  }, []);
  // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     history("/");
  //   } else {
  //     history("/login");
  //   }
  //   console.log("hii");
  // });
  const startListening = () => {
    setTranscript("");
    setListening(true);

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = "hi-IN"; // Set the language to Hindi

    recognition.onstart = () => {
      console.log("Speech recognition started");
    };

    recognition.onresult = (event) => {
      const result = event.results[event.results.length - 1][0].transcript;
      setTranscript(result);
    };

    recognition.onend = () => {
      setListening(false);
      console.log("Speech recognition ended");
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setListening(false);
    };

    recognition.start();
  };

  const stopListening = () => {
    setListening(false);
  };
  const [englishText, setEnglishText] = useState("");
  const [hindiText, setHindiText] = useState("");
  const translateText = async (text, tr) => {
    let apiUrl = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=hi&dt=t&q=${text}`;
    let dat;
    await fetch(apiUrl)
      .then((res) => res.json())
      .then((data) => {
        console.log(data[0]);
        const res = data[0];

        // console.log(data.responseData.translatedText);
        dat = res[0][0];
      });
    return dat;
  };
  const handleTranslate = async () => {
    // Translate English to Hindi
    const translatedText = await translateText(englishText, "hi"); // 'hi' is the language code for Hindi
    console.log(translatedText);
    setHindiText(translatedText);
  };

  return (
    <Container
      sx={{
        bgcolor: "#fffff",
        // height: "100vh",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        textAlign: "center",
        background: `linear-gradient(90deg, #C7C5F4, #776BCC)`,
        backgroundSize: "cover",
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/vidhanSabha.jpeg`} // Replace with the actual path to your logo image
        alt="Logo"
        style={{
          position: "absolute",
          top: "20px", // Adjust this value to control the vertical positioning
          left: "20px", // Adjust this value to control the horizontal positioning
          width: "100px", // Adjust the width as needed
          height: "auto", // Maintain aspect ratio
          borderRadius: "50%", // Apply a border-radius for a circular shape (optional)
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Apply a subtle shadow for a lifted look (optional)
        }}
      />

      <Box style={{ zIndex: 2 }}>
        {JSON.parse(localStorage.getItem("items")) &&
          JSON.parse(localStorage.getItem("items")).role === "admin" && (
            <Button
              style={{ position: "absolute", right: "120px", top: "15px" }}
              variant="contained"
            >
              <Link to="/admin/dashboard">Dashboard</Link>
            </Button>
          )}
        <Button
          style={{ position: "absolute", right: "15px", top: "15px" }}
          onClick={logout}
          variant="contained"
        >
          Logout
        </Button>
        <h1 style={{ textAlign: "center", marginTop: "50px" }}>वाक्यश्रवण</h1>
        <p>Listening: {listening ? "Yes" : "No"}</p>
        <Button
          style={{ margin: "10px" }}
          variant="contained"
          onClick={startListening}
          disabled={listening}
        >
          Start Listening
        </Button>
        <Button
          variant="contained"
          onClick={stopListening}
          disabled={!listening}
        >
          Stop Listening
        </Button>
        <p>Transcript:</p>
        <Box
          style={{
            border: "1px solid blue",
            borderRadius: "5px",
            padding: "10px",
            marginTop: "10px",
          }}
        >
          <p style={{ fontFamily: "Mangal", fontSize: "16px" }}>{transcript}</p>
        </Box>
        <Box style={{ marginTop: "50px" }}>
          <h2 style={{ textAlign: "center", marginTop: "50px" }}>
            Translate to Hindi{" "}
          </h2>

          <Box>
            <TextField
              type="text"
              value={englishText}
              onChange={(e) => setEnglishText(e.target.value)}
              id="outlined-basic"
              label="English Text"
              variant="outlined"
              fullWidth
            />
          </Box>
          <Button
            style={{ marginTop: "20px" }}
            variant="contained"
            onClick={handleTranslate}
          >
            Translate
          </Button>
          {hindiText && (
            <div
              style={{
                marginTop: "30px",
              }}
            >
              <h2>Translated Hindi Text:</h2>
              <div
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                {hindiText}
              </div>
            </div>
          )}
        </Box>
      </Box>
      <div class="screen__background">
        <span class="screen__background__shape screen__background__shape4"></span>
        <span class="screen__background__shape screen__background__shape3"></span>
        <span class="screen__background__shape screen__background__shape2"></span>
        <span class="screen__background__shape screen__background__shape1"></span>
      </div>
      <footer style={{ marginTop: "auto", color: "#888" }}>
        Powered by Gemperts
      </footer>
    </Container>
  );
};

export default Translater;
