import React, { useState } from "react";
// import { use } from "react-router";
import { toast } from "react-toastify";
import "../Login/login.css";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const history = useNavigate();

  const registerUser = (email, password, mobile, role, name) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        password: password,
        role: role,
        mobileNo: mobile,
        name: name,
      }),
    };
    fetch("https://real-hat-pike.cyclic.app/user/register", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        //  localStorage.setItem("items", JSON.stringify(data));
        if (data.name) {
          console.log(data);
          history("/admin/dashboard");
        }
      });
  };
  console.log(pass);
  console.log(email);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !pass) {
      return toast.warning("Please fill in all fields");
    } else {
      registerUser(email, pass, mobile, role, name);
    }
  };

  return (
    <div class="container">
      <div class="screen">
        <div class="screen__content">
          <form class="login" onSubmit={handleSubmit}>
            <div class="login__field">
              <i class="login__icon fas fa-user"></i>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                class="login__input"
                placeholder="Email"
              />
            </div>
            <div class="login__field">
              <i class="login__icon fas fa-lock"></i>
              <input
                type="password"
                class="login__input"
                placeholder="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
            <div class="login__field">
              <i class="login__icon fas fa-user"></i>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                class="login__input"
                placeholder="Name"
              />
            </div>
            <div class="login__field">
              <i class="login__icon fas fa-user"></i>
              <input
                value={role}
                onChange={(e) => setRole(e.target.value)}
                type="text"
                class="login__input"
                placeholder="role( admin / user )"
              />
            </div>
            <div class="login__field">
              <i class="login__icon fas fa-user"></i>
              <input
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                type="text"
                class="login__input"
                placeholder="Mobile"
              />
            </div>

            <button class="button login__submit">
              <span class="button__text">Submit</span>
              <i class="button__icon fas fa-chevron-right"></i>
            </button>
          </form>
          <div class="social-login">
            <h2>
              <a href="">वाक्यश्रवण</a>
            </h2>
          </div>
        </div>
        <div class="screen__background">
          <span class="screen__background__shape screen__background__shape4"></span>
          <span class="screen__background__shape screen__background__shape3"></span>
          <span class="screen__background__shape screen__background__shape2"></span>
          <span class="screen__background__shape screen__background__shape1"></span>
        </div>
      </div>
    </div>
    // <div className="container">
    //   <div className="row">
    //     <div className="col-md-12">
    //       <h1 className="text-center font-weight-bolder py-5">
    //         React Firebase Simple Blog
    //         <span className="text-primary"> [Admin]</span>
    //       </h1>

    //       <div className="col-md-5 p-2 mt-5 mx-auto">
    //         <form onSubmit={handleSubmit}>
    //           <div className="form-group">
    //             <input
    //               type="text"
    //               name="email"
    //               className="form-control"
    //               placeholder="Email"
    //               value={email}
    //               onChange={(e) => setEmail(e.target.value)}
    //             />
    //           </div>
    //           <div className="form-group">
    //             <input
    //               type="password"
    //               name="password"
    //               className="form-control"
    //               placeholder="Password"
    //               value={pass}
    //               onChange={(e) => setPass(e.target.value)}
    //             />
    //           </div>
    //           <div className="form-group">
    //             <button type="submit" className="btn btn-block btn-dark">
    //               Login
    //             </button>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Register;
