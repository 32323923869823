import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";

const Dashboard = () => {
  const history = useNavigate();
  const logout = () => {
    localStorage.removeItem("items");
    history("/login");
  };

  const [usersData, setUsersData] = useState([]);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch("https://real-hat-pike.cyclic.app/user/allUsers", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // localStorage.setItem("items", JSON.stringify(data));
        setUsersData(data);
      });
  }, []);
  console.log(usersData);
  return (
    <Container style={{ marginTop: "20px" }}>
      <div>
        <h1 style={{ textAlign: "center", marginTop: "30px" }}>Hello Admin</h1>
      </div>
      <Button onClick={logout} variant="contained">
        Logout
      </Button>
      <span style={{ marginLeft: "15px" }}>
        <Button variant="contained" component="a" href="/">
          {/* <Link to="/"> */}
          Main App
          {/* </Link> */}
        </Button>
      </span>
      <h3 style={{ textAlign: "center", marginTop: "30px" }}>
        Current Users{" "}
        <span>
          {" "}
          <Button variant="contained" component="a" href="/admin/register">
            {/* <Link to="/admin/register"> */}
            Add user
            {/* </Link> */}
          </Button>
        </span>
      </h3>
      <Container>
        <TableContainer style={{ margin: "15px" }} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">mobileNo</TableCell>
                <TableCell align="left">name</TableCell>
                <TableCell align="left">password</TableCell>
                <TableCell align="left">role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData.map((row) => (
                <TableRow
                  key={row.email}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left" component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell align="left">{row.mobileNo}</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.password}</TableCell>
                  <TableCell align="left">{row.role}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Container>
  );
};

export default Dashboard;
