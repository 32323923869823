import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Auth/Login/Login";
import Register from "./Auth/Register/Register";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fire } from "../config/firebase";
import Dashboard from "./Dashboard/Dashboard";

const Admin = () => {
  const history = useNavigate();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (!items) {
      history("/login");
    }
  }, [items]);

  const loginUser = (email, password) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, pass: password }),
    };
    fetch("https://real-hat-pike.cyclic.app/user/login", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("items", JSON.stringify(data));
        console.log(data);
      });
    // fire
    //   .auth()
    //   .signInWithEmailAndPassword(email, password)
    //   .then((user) => {
    //     console.log(user);
    //     dispatch({ type: "SET_USER", payload: user });
    //     toast.success("Successfully Logged In");
    //     history("/admin/dashboard");
    //   })
    //   .catch((err) => {
    //     if (err.code === "auth/user-not-found") {
    //       return toast.error("Invalid Email or Password");
    //     }
    //     if (err.code === "auth/invalid-email") {
    //       return toast.error("Please enter valid email");
    //     }
    //   });
  };

  const registerUser = ({ name, email, password, confirmPassword }) => {
    if (!name || !email || !password || !confirmPassword) {
      return toast.warning("Please fill in all fields!!");
    }

    if (password !== confirmPassword) {
      return toast.warning("Passwords donot match!");
    }

    fire
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        console.log(user);
        const currentUser = fire.auth().currentUser;
        console.log(currentUser);
        currentUser.updateProfile({
          displayName: name,
        });
        // dispatch({ type: "SET_USER", payload: currentUser });
        history("/admin/dashboard");
      })
      .catch((err) => {
        if (err.code === "auth/email-already-in-use") {
          toast.error("User already exists");
        }
      });
  };
  //   const { path } = useMatch();
  return (
    <Routes>
      <Route
        path="/register"
        element={<Register registerUser={registerUser}></Register>}
      ></Route>
      <Route
        path="/login"
        element={<Login loginUser={loginUser}></Login>}
      ></Route>
      <Route path="/dashboard" element={<Dashboard></Dashboard>}></Route>

      {/* <div>llo Admin</div>;s */}
    </Routes>
  );
};

export default Admin;
