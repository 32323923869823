import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Translater from "./translater";
import Admin from "./Admin";
import Login from "./Admin/Auth/Login/Login";
import { Alert, Snackbar } from "@mui/material";

const App = () => {
  const [open, openSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const history = useNavigate();

  const [items, setItems] = useState([]);

  const loginUser = (email, password) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, pass: password }),
    };
    fetch("https://real-hat-pike.cyclic.app/user/login", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!data.email) {
          setSnackbarMessage("Email or Password is incorrect");

          openSnackbar(true);
        } else {
          localStorage.setItem("items", JSON.stringify(data));
          if (data && data.role === "user") {
            history("/");
          } else if (data && data.role === "admin") {
            history("/admin/dashboard");
          }
        }
        console.log(data);
      });
    // fire
    //   .auth()
    //   .signInWithEmailAndPassword(email, password)
    //   .then((user) => {
    //     console.log(user);
    //     dispatch({ type: "SET_USER", payload: user });
    //     toast.success("Successfully Logged In");
    //     history("/admin/dashboard");
    //   })
    //   .catch((err) => {
    //     if (err.code === "auth/user-not-found") {
    //       return toast.error("Invalid Email or Password");
    //     }
    //     if (err.code === "auth/invalid-email") {
    //       return toast.error("Please enter valid email");
    //     }
    //   });
  };
  const handleClose = () => {
    openSnackbar(false);
  };
  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Routes>
        <Route
          path="/login"
          element={<Login loginUser={loginUser}></Login>}
        ></Route>
        <Route exact path="/" Component={Translater}></Route>
        <Route path="/admin/*" Component={Admin}></Route>
      </Routes>
    </>
  );
};

export default App;
